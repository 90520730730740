@charset "UTF-8";
/* ------------------------------------------------------
デスクトップ・スマホ共通
 ------------------------------------------------------ */
body {
  margin: 0;
  padding: 0;
  color: #61392f;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 300;
  background: #efe7e5;
  position: relative; }
  @media screen and (max-width: 62em) {
    body.sp-menu-open {
      overflow: hidden; } }

h1, h2, h3 {
  font-weight: 300;
  letter-spacing: 0.1rem;
  font-feature-settings: "palt"; }

table {
  margin: 1em 0;
  width: 100%;
  font-size: 110%; }

th, td {
  vertical-align: top;
  padding: 0.4em;
  border: 1px dotted #5D3F37; }

th {
  text-align: left; }

td ol, td ul {
  margin: 0; }

dt {
  font-weight: 600; }

dd {
  margin: 0 0 1em; }

a {
  color: #61392f; }
  a:hover {
    color: #444; }

span.en {
  font-family: 'Libre Baskerville', serif;
  font-weight: 700; }

span.jp {
  font-size: 50%;
  display: block; }

/* ---------------------------
header
*/
header .title {
  margin-bottom: 15px; }
  header .title a {
    color: #444; }
    header .title a:hover {
      text-decoration: none; }

header .sns li {
  font-size: 24px; }
  header .sns li#nav-sns-youtube a {
    color: #ff0500; }
  header .sns li#nav-sns-line {
    height: 29px;
    width: 29px;
    border-radius: 50%;
    overflow: hidden;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 2px solid #25C131;
    margin-right: .2rem;
    background: #fff; }
    header .sns li#nav-sns-line a {
      color: #25C131;
      font-size: 32px; }

@media screen and (min-width: 62.0625em) {
  header .container {
    position: relative;
    max-width: 1024px; } }
  @media screen and (min-width: 62.0625em) and (max-width: 62em) {
    header .container {
      max-width: 900px; } }

@media screen and (min-width: 62.0625em) {
  header .title {
    padding: 58px 0 20px;
    text-align: center; }
    header .title a {
      justify-content: center;
      font-size: 1.6rem;
      font-weight: 400; }
    header .title img {
      width: 180px; }
  header nav {
    display: flex;
    justify-content: center;
    margin-bottom: 18px; }
  header ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
    header ul.gnav li {
      padding: 0 .4rem;
      font-feature-settings: "palt";
      text-align: center; }
      header ul.gnav li a {
        padding: .2rem .9rem;
        text-decoration: none;
        display: block;
        font-size: 19px;
        font-weight: 800; }
        header ul.gnav li a span.jp {
          font-size: 11px;
          font-weight: 400;
          display: block; }
        header ul.gnav li a:hover {
          text-decoration: none;
          color: #759620; }
    header ul.sns {
      align-items: center; }
      header ul.sns li a {
        padding: .2rem .4rem; }
  header .overlay, header .menu-trigger {
    display: none; }
  header .sns {
    position: absolute;
    top: 15px;
    right: 20px; } }

@media screen and (max-width: 62em) {
  header {
    position: relative; }
    header .container {
      max-width: none; }
    header .title {
      padding: 3px 0 14px;
      z-index: -1;
      margin-bottom: 0; }
      header .title .logo {
        font-size: 2rem; }
        header .title .logo img {
          width: 160px; }
      header .title div {
        font-size: .7rem; }
        header .title div strong {
          font-size: 1rem; }
    header .menu-trigger {
      display: inline-block;
      width: 28px;
      height: 34px;
      vertical-align: middle;
      cursor: pointer;
      position: fixed;
      top: 33px;
      right: 18px;
      z-index: 2002;
      transform: translateX(0); }
      header .menu-trigger span {
        display: inline-block;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #61392f;
        transition: all .5s; }
        header .menu-trigger span:nth-of-type(1) {
          top: 3px; }
        header .menu-trigger span:nth-of-type(2) {
          top: 9px; }
        header .menu-trigger span:nth-of-type(3) {
          top: 15px; }
      header .menu-trigger div {
        font-size: 10px;
        text-align: center;
        bottom: 0;
        position: absolute;
        letter-spacing: -.04rem; }
      header .menu-trigger.active {
        transform: translateX(0);
        z-index: 10000; }
        header .menu-trigger.active span {
          background-color: #61392f !important; }
          header .menu-trigger.active span:nth-of-type(1) {
            transform: translateY(6px) rotate(-45deg); }
          header .menu-trigger.active span:nth-of-type(2) {
            opacity: 0; }
          header .menu-trigger.active span:nth-of-type(3) {
            transform: translateY(-6px) rotate(45deg); }
    header nav {
      display: none;
      width: 100vw;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1600;
      transform: translate(-100vw);
      transition: all .5s;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      padding: 100px 0 0; }
      header nav ul {
        margin: 0 0 20px;
        padding: 0 35px; }
        header nav ul li {
          padding: .8rem 0;
          border-bottom: 1px solid transparent; }
          header nav ul li:hover {
            border-bottom: 1px solid #61392f; }
          header nav ul li a {
            color: #61392f;
            display: block;
            text-decoration: none;
            border-bottom: 1px solid transparent; }
            header nav ul li a:hover {
              text-decoration: none; }
            header nav ul li a span {
              vertical-align: middle; }
            header nav ul li a span.jp {
              display: inline-block;
              padding-left: .5rem;
              font-size: .8rem;
              font-feature-settings: "palt";
              letter-spacing: .08rem; }
        header nav ul.sns {
          display: flex;
          align-items: center; }
          header nav ul.sns li {
            display: flex;
            align-items: center;
            padding: 2px 0;
            margin: 0 .4rem;
            justify-content: center;
            border-bottom: 0;
            height: 29px;
            width: 29px; }
          header nav ul.sns li#nav-sns-line {
            padding-top: 0; }
            header nav ul.sns li#nav-sns-line a {
              padding-top: 4px; }
          header nav ul.sns li#nav-sns-instagram {
            margin-left: .1rem; }
      header nav.open {
        display: block;
        transform: translateX(0);
        overflow: hidden;
        z-index: 10000; }
        header nav.open ul {
          box-sizing: border-box; }
          header nav.open ul li {
            list-style: none; }
    header .overlay {
      content: "";
      display: block;
      width: 0;
      height: 0;
      background: rgba(255, 255, 255, 0.9);
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .5s;
      top: 0;
      z-index: 0; }
      header .overlay.open {
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 1001; } }
  @media screen and (max-width: 62em) and (max-width: 48em) {
    header .title {
      padding-bottom: 12px; }
      header .title .logo img {
        width: 110px;
        margin-top: 5px; }
    header .menu-trigger {
      top: 25px; }
    header nav.open {
      padding: 90px 0 0; } }

.parallax {
  position: fixed;
  z-index: 1000; }
  .parallax img {
    height: auto; }
    .parallax img.f1 {
      width: 10.55vw;
      max-width: 153px; }
    .parallax img.f2 {
      width: 8.88vw;
      max-width: 128px; }
    .parallax img.f3 {
      width: 13.06vw;
      max-width: 188px; }
    .parallax img.f4 {
      width: 11.25vw;
      max-width: 162px; }
    .parallax img.f5 {
      width: 6.6vw;
      max-width: 95px; }
    .parallax img.f6 {
      width: 10.14vw;
      max-width: 146px; }
    .parallax img.f7 {
      width: 9.86vw;
      max-width: 142px; }
    .parallax img.f8 {
      width: 9.44vw;
      max-width: 136px; }
  .parallax#top-parallax1 {
    left: 28.33vw;
    top: -2.43vw;
    display: block; }
    @media screen and (max-width: 62em) {
      .parallax#top-parallax1 {
        position: absolute;
        left: 40vw; } }
  .parallax#top-parallax2 {
    left: 12.85vw;
    top: 52.08vw; }
  .parallax#top-parallax3 {
    right: 10.76vw;
    top: 60.07vw; }
  .parallax#bottom-parallax3 {
    right: 5.07vw;
    bottom: 45px;
    position: absolute;
    display: block; }
    @media screen and (max-width: 36em) {
      .parallax#bottom-parallax3 {
        right: 2vw; }
        .parallax#bottom-parallax3 img {
          width: 15vw; } }
  @media screen and (max-width: 62em) {
    .parallax {
      display: none; }
      .parallax#bottom-parallax1 {
        position: absolute;
        display: block;
        top: auto !important;
        right: 10vw !important;
        bottom: 360px;
        z-index: 4000; }
        .parallax#bottom-parallax1 img {
          width: 15.5vw !important; }
      .parallax#bottom-parallax2 {
        position: absolute;
        display: block;
        top: auto !important;
        left: 4vw !important;
        bottom: 245px; }
        .parallax#bottom-parallax2 img {
          width: 12vw !important; } }

/* ---------------------------
footer
*/
footer {
  text-align: center; }
  footer h2 span.jp {
    padding-top: .3rem;
    font-weight: 600; }
  footer .links {
    display: flex;
    justify-content: center;
    align-items: center; }
    footer .links a {
      height: 120px;
      width: 120px;
      margin: 0 23px; }
  footer .copyright {
    background: #fff;
    font-size: 18px;
    font-family: 'Libre Baskerville', serif;
    font-weight: 700;
    padding: 17px; }
  @media screen and (min-width: 48.0625em) {
    footer {
      padding: 100px 0 0; }
      footer h2 {
        margin-bottom: 32px;
        font-size: 32px; }
      footer .links {
        margin-top: 55px; }
      footer .copyright {
        margin-top: 238px; } }
  @media screen and (min-width: 48.0625em) and (max-width: 62em) {
    footer {
      padding-top: 0; }
      footer .copyright {
        margin-top: 80px; } }
  @media screen and (max-width: 48em) {
    footer {
      padding-top: 15px; }
      footer h2 {
        margin-bottom: 28px;
        font-size: 22px; }
      footer p {
        font-size: 14px; }
      footer .links {
        margin-top: 15px; }
        footer .links a {
          height: 80px;
          width: 80px;
          margin: 0 8px;
          display: block; }
          footer .links a img {
            width: 100%; }
      footer .copyright {
        margin-top: 80px;
        font-size: .8rem; } }

#page-top {
  position: fixed;
  display: none !important;
  right: 10px; }
  @media screen and (min-width: 48.0625em) {
    #page-top {
      bottom: 20px;
      z-index: 3000; } }
  @media screen and (max-width: 48em) {
    #page-top {
      bottom: 10px; } }
  #page-top a {
    display: block;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    color: #61392f;
    text-decoration: none;
    font-size: 10px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); }
    #page-top a span {
      display: block; }

/* ---------------------------
contents
*/
#contents p {
  font-size: 1rem;
  line-height: 1.8; }
  #contents p a {
    text-decoration: underline; }

#contents h2 {
  font-size: 1.875rem;
  margin-top: 0.4rem;
  font-feature-settings: "palt"; }
  #contents h2 span {
    font-size: 1rem; }
  #contents h2.heading span.en {
    letter-spacing: -.01rem; }
  #contents h2.heading span.jp {
    font-weight: 600; }
  @media screen and (min-width: 48.0625em) {
    #contents h2.heading span.en {
      font-size: 32px; }
    #contents h2.heading span.jp {
      font-size: 14px;
      margin-top: .2rem; } }
  @media screen and (max-width: 48em) {
    #contents h2.heading span.en {
      font-size: 22px; }
    #contents h2.heading span.jp {
      font-size: 12px;
      margin-top: .3rem; } }

#contents h3 {
  font-size: 1.3rem;
  margin-top: 0.4rem; }

#contents li a {
  text-decoration: underline; }

@media screen and (min-width: 62.0625em) {
  #contents {
    padding: 45px 0 30px; } }

@media screen and (max-width: 48em) {
  #contents h2 {
    font-size: 1.4rem; }
  #contents h3 {
    font-size: 1.1rem; }
  #contents #lead {
    font-feature-settings: "palt"; } }

#contents .content-nav a {
  background: #f9f9f9;
  padding: .5rem;
  border-radius: 3px;
  display: block;
  margin-bottom: 1rem; }
  #contents .content-nav a:hover {
    text-decoration: none;
    background: #f5f5f5; }

#contents .viewmore {
  text-align: center; }

#contents a.btn {
  display: inline-block;
  text-align: center;
  background: #603930;
  color: #fff;
  border: 2px solid #603930;
  border-radius: 10px;
  padding: .9rem 3rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none; }
  #contents a.btn:hover {
    background: #fff;
    color: #603930; }
  @media screen and (max-width: 30.0625em) {
    #contents a.btn {
      padding: .6rem 1rem;
      width: 100%; } }

#contents .btn-primary {
  font-weight: 300; }

#headline {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 62.0625em) {
    #headline {
      margin-top: 30px;
      height: 25.34vw; } }
  @media screen and (max-width: 62em) {
    #headline {
      margin-bottom: 2rem;
      height: 32vw; } }
  #headline h1 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 0; }
    @media screen and (min-width: 62.0625em) {
      #headline h1 {
        font-size: 1.875rem; }
        #headline h1 img {
          margin-top: 3.4vw; }
        #headline h1 span {
          padding: 10px 0 1.8rem;
          color: #fff;
          font-size: 12px; } }
    @media screen and (max-width: 62em) {
      #headline h1 {
        font-size: 1.3rem;
        letter-spacing: .1rem; }
        #headline h1 img {
          max-width: 60%; }
        #headline h1 span {
          padding: 0 1rem .5rem;
          color: #fff;
          font-size: 12px; } }

/* ---------------------------
ホーム
*/
.home .movie {
  position: relative;
  width: 100%; }
  .home .movie #top-movie {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .home .movie .feature-title {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%; }
    .home .movie .feature-title img {
      width: 80%;
      max-width: 563px; }

.home #contents {
  z-index: 1000 !important;
  position: relative; }
  @media screen and (min-width: 62.0625em) {
    .home #contents {
      padding-top: 0; } }
  .home #contents h2 {
    margin-bottom: 1.7rem; }
    .home #contents h2 a {
      text-decoration: none; }
  .home #contents #instagram {
    min-height: 440px; }
    .home #contents #instagram h2 {
      font-size: 2.7rem;
      margin-top: 0; }
    @media screen and (min-width: 62.0625em) {
      .home #contents #instagram h2 {
        margin-bottom: 1.3rem; } }
    @media screen and (max-width: 62em) {
      .home #contents #instagram {
        padding: 10px 0 0; }
        .home #contents #instagram h2 {
          margin-bottom: .9rem; } }

.home .insta-photos a:before, .home .insta-photos div:before {
  content: "";
  display: block;
  padding-top: 100%; }

.home .insta-photos a img, .home .insta-photos a video, .home .insta-photos div img, .home .insta-photos div video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: bottom;'; }

@media screen and (min-width: 48.0625em) {
  .home #feature {
    max-height: 728px;
    overflow: hidden; }
  .home .movie {
    padding-top: 56.25%; }
  .home .feature-title {
    max-height: 728px; }
  .home #shopinfo h2 {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.9;
    margin-bottom: 2rem;
    text-align: center; }
  .home #shopinfo img {
    margin-bottom: 2rem;
    max-width: 405px; } }

@media screen and (max-width: 48em) {
  .home .movie {
    padding-top: 75%;
    overflow: hidden; }
    .home .movie #top-movie {
      transform: translate(-12.5%, 0%);
      height: 75vw;
      width: auto; }
  .home #shopinfo h2 {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.9;
    margin-bottom: 2rem;
    text-align: left; } }

.home #bottom-parallax1 {
  right: 20.3vw;
  top: 2550px; }
  @media screen and (max-width: 75em) {
    .home #bottom-parallax1 {
      right: 14vw; } }

.home #bottom-parallax2 {
  left: 12.85vw;
  top: 2800px; }

.home #parallax1 {
  right: 12.57vw;
  top: 365px; }

.home #parallax2 {
  left: 9.51vw;
  top: 230px; }
  @media (max-width: 1340px) {
    .home #parallax2 {
      left: 1vw; } }

.home #parallax3 {
  left: 9.51vw;
  top: 1520px; }

.home #parallax4 {
  right: 23vw;
  top: 935px; }

.home #parallax5 {
  right: 15.28vw;
  top: 1760px; }

.home #parallax6 {
  left: 27.78vw;
  top: 2230px; }
  @media screen and (max-width: 75em) {
    .home #parallax6 {
      left: 22vw; } }

/* ---------------------------
お店の紹介
*/
.about #headline {
  background: url("/_uploads/about-headline-bg.jpg") no-repeat/cover; }

.about #contents h2 {
  font-family: 'Libre Baskerville', serif;
  font-weight: 700; }
  .about #contents h2 .small {
    font-size: 1rem;
    display: inline-block;
    margin-left: 0.5rem; }

.about #contents #lead p {
  line-height: 2; }

.about #contents #store {
  background-color: #f6f2f1;
  background-image: url("/_assets/img/bg1-t.png"), url("/_assets/img/bg1-b.png");
  background-position: top center , bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100vw; }
  .about #contents #store img.store-illust {
    margin-bottom: 30px; }
  .about #contents #store h3 {
    font-weight: 600;
    margin: 0 0 1.2rem; }
  .about #contents #store p {
    line-height: 2.2;
    color: #61392f;
    font-size: 13px;
    font-weight: 600; }
  .about #contents #store img.store-pay {
    margin-top: 10px; }
    @media screen and (min-width: 48.0625em) {
      .about #contents #store img.store-pay {
        max-width: 380px; } }

.about #contents #staff {
  padding-bottom: 30px; }
  .about #contents #staff h2 {
    margin-bottom: 1.8rem; }
  .about #contents #staff p {
    text-align: center;
    margin-top: .8rem; }
  .about #contents #staff .about-staff {
    mask-image: url("/_uploads/about-staff-mask.png");
    mask-repeat: no-repeat;
    mask-size: 100%; }

.about #contents #map {
  background-color: #f6f2f1;
  background-image: url("/_assets/img/bg2-t.png"), url("/_assets/img/bg2-b.png");
  background-position: top center , bottom center;
  background-repeat: no-repeat;
  background-size: contain; }
  .about #contents #map .gmap {
    position: relative;
    width: 100%;
    padding-top: 56.25%; }
    .about #contents #map .gmap iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

@media screen and (min-width: 48.0625em) {
  .about #contents #lead {
    padding-bottom: 70px; }
    .about #contents #lead p {
      text-align: center; }
      .about #contents #lead p img {
        margin-top: 15px;
        max-width: 468px; }
  .about #contents #store {
    padding: 55px 0 90px; }
    .about #contents #store h2 {
      margin-bottom: 60px; }
  .about #contents #map {
    padding: 75px 0 1px;
    margin-bottom: 55px; }
    .about #contents #map p {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 2.5rem; }
      .about #contents #map p.viewmore {
        margin-top: 40px; } }

@media screen and (max-width: 48em) {
  .about #contents {
    padding-bottom: 40px; }
    .about #contents #lead {
      padding-bottom: 30px; }
      .about #contents #lead p {
        font-size: 14px; }
    .about #contents #store {
      padding: 35px 0 40px; }
      .about #contents #store h2 {
        margin-bottom: 20px; }
      .about #contents #store p {
        font-feature-settings: "palt"; }
        .about #contents #store p img.store-pay {
          margin: 15px 0 20px;
          display: block; }
    .about #contents #staff {
      padding-top: 10px; }
    .about #contents #map {
      padding: 30px 0 40px; }
      .about #contents #map h2 {
        margin-bottom: 20px; }
      .about #contents #map p {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 20px; } }

.about #bottom-parallax1 {
  right: 22.3vw;
  top: 2200px; }

.about #bottom-parallax2 {
  left: 12.85vw;
  top: 3000px; }

.about #parallax1 {
  left: 32.08vw;
  top: 1560px; }
  @media (min-width: 1500px) {
    .about #parallax1 {
      left: 5vw; } }

.about #parallax2 {
  right: 21.6vw;
  top: 2100px; }

.about #parallax3 {
  left: 14.375vw;
  top: 2600px; }

.about #parallax4 {
  left: 29.86vw;
  top: 110.05vw; }

.about #parallax5 {
  right: 19.8vw;
  top: 130.21vw; }

.about #parallax6 {
  left: 19.8vw;
  top: 135.21vw; }

/* ---------------------------
体験・教室
*/
.lesson #headline {
  background: url("/_uploads/lesson-headline-bg.jpg") no-repeat/cover;
  margin-bottom: 0; }
  .lesson #headline h1 img {
    padding-left: 2vw;
    padding-right: 5vw; }

.lesson #contents {
  padding-top: 0; }

.lesson #lead {
  background: #f6f2f1 url("/_assets/img/bg3-b.png") bottom center no-repeat/contain; }
  .lesson #lead p {
    font-size: .95rem;
    line-height: 2.1; }

.lesson #workshop .row .post, .lesson #lesson .row .post {
  margin: 0 auto; }
  .lesson #workshop .row .post iframe, .lesson #lesson .row .post iframe {
    width: 100%;
    min-width: 100% !important; }

.lesson #workshop .viewmore, .lesson #lesson .viewmore {
  margin: 35px 0 38px; }
  .lesson #workshop .viewmore .btn, .lesson #lesson .viewmore .btn {
    padding: 1.15rem 1.4rem;
    font-size: 1rem; }
  @media screen and (max-width: 48em) {
    .lesson #workshop .viewmore, .lesson #lesson .viewmore {
      margin-bottom: 50px; } }

.lesson #workshop p.ws-image img {
  mask-image: url("/_uploads/lesson-ws-mask.png");
  mask-repeat: no-repeat;
  mask-size: 100%;
  object-fit: contain; }

.lesson #lesson {
  background-color: #f6f2f1;
  background-image: url("/_assets/img/bg4-t.png"), url("/_assets/img/bg4-b.png");
  background-position: top center , bottom center;
  background-repeat: no-repeat;
  background-size: contain; }
  .lesson #lesson .lesson-image img {
    mask-image: url("/_uploads/lesson-lesson-mask.png");
    mask-repeat: no-repeat;
    mask-size: 100%;
    object-fit: contain; }
  .lesson #lesson p {
    font-size: 14px;
    line-height: 2; }

@media screen and (min-width: 48.0625em) {
  .lesson #lead {
    padding: 40px 0 48px;
    margin-bottom: 62px; }
    .lesson #lead p {
      text-align: center; }
  .lesson #workshop h2 {
    margin-bottom: 50px; }
  .lesson #workshop p.ws-image {
    margin-bottom: 35px; }
    .lesson #workshop p.ws-image + p {
      text-align: center; }
  .lesson #lesson {
    padding: 80px 0;
    margin-bottom: 78px; }
    .lesson #lesson h2 {
      margin-bottom: 60px; }
    .lesson #lesson p {
      text-align: center; }
    .lesson #lesson .lesson-image {
      margin-bottom: 65px; } }

@media screen and (max-width: 48em) {
  .lesson #contents p {
    font-size: 14px; }
  .lesson #headline {
    margin-bottom: 0; }
  .lesson #lead {
    padding: 40px 0 38px;
    margin-bottom: 32px; }
  .lesson #workshop h2 {
    margin-bottom: 30px; }
  .lesson #workshop p.ws-image {
    margin-bottom: 25px; }
  .lesson #lesson {
    padding: 44px 0 30px;
    margin-bottom: 35px; } }
  @media screen and (max-width: 48em) and (min-width: 36.0625em) {
    .lesson #lesson {
      padding-bottom: 80px; } }

@media screen and (max-width: 48em) {
    .lesson #lesson h2 {
      margin-bottom: 30px; }
    .lesson #lesson .lesson-image {
      margin-bottom: 30px; } }

.lesson #bottom-parallax1 {
  right: 22.3vw;
  top: 2200px; }
  @media (max-width: 1440px) {
    .lesson #bottom-parallax1 {
      right: 14vw; } }

.lesson #bottom-parallax2 {
  left: 12.85vw;
  top: 3000px; }

.lesson #parallax1 {
  left: 20.13vw;
  top: 1500px; }

.lesson #parallax2 {
  right: 21.6vw;
  top: 2100px; }

.lesson #parallax3 {
  left: 12.5vw;
  top: 4000px; }

.lesson #parallax4 {
  right: 20.83vw;
  top: 5000px; }

/* ---------------------------
ギフト
*/
.gift #headline {
  background: url("/_uploads/gift-headline-bg.jpg") no-repeat/cover; }

.gift #contents h2 {
  letter-spacing: .2rem; }

.gift #contents #lead p {
  font-size: .95rem;
  line-height: 2; }

.gift #contents #gift {
  background: #f6f2f1 url("/_assets/img/bg5-t.png") top center no-repeat/contain;
  padding: 104px 0 0; }
  .gift #contents #gift p.gift-illust {
    margin-bottom: 35px; }

.gift #contents #showcase {
  background: #f6f2f1 url("/_assets/img/bg5-b.png") bottom center no-repeat/contain; }
  .gift #contents #showcase .slider .slick-arrow {
    z-index: 10000;
    height: 64px;
    width: 18px;
    top: 30%; }
  .gift #contents #showcase .slider .slick-prev {
    left: -20px; }
  .gift #contents #showcase .slider .slick-next {
    right: -20px; }
  .gift #contents #showcase .slider .slick-prev:before {
    content: url("/_assets/img/slick-arrow-left.png"); }
  .gift #contents #showcase .slider .slick-next:before {
    content: url("/_assets/img/slick-arrow-right.png"); }
  .gift #contents #showcase .slider .slick-prev:before, .gift #contents #showcase .slider .slick-next:before {
    height: 64px;
    width: 18px; }
  .gift #contents #showcase .slider .slide {
    padding: 0 8px; }
    .gift #contents #showcase .slider .slide p {
      padding: 15px 0;
      font-size: 14px;
      line-height: 1.75; }
      .gift #contents #showcase .slider .slide p span {
        font-size: 80%;
        padding: 0 .1rem; }

.gift #contents #line p.line-mark {
  margin-bottom: 42px; }

.gift #contents #line #flow .flow {
  border: 1px solid #61392f;
  display: flex;
  padding: 40px 50px 28px; }
  .gift #contents #line #flow .flow .num {
    font-family: 'Libre Baskerville', serif;
    font-weight: 700;
    font-size: 24px;
    padding-right: 20px;
    width: 4rem; }
  .gift #contents #line #flow .flow .text {
    width: calc(100% - 4rem);
    padding-top: 5px; }
    .gift #contents #line #flow .flow .text p {
      font-size: 14px; }

.gift #contents #delivery {
  background-color: #f6f2f1;
  background-image: url("/_assets/img/bg6-t.png"), url("/_assets/img/bg6-b.png");
  background-position: top center , bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 70px 0 145px;
  margin-bottom: 55px; }
  .gift #contents #delivery h2 {
    margin-bottom: 3.6rem; }
  .gift #contents #delivery .fee p {
    font-size: 13px;
    line-height: 2.3; }
  .gift #contents #delivery p {
    line-height: 1.5; }

@media screen and (min-width: 48.0625em) {
  .gift #contents #lead p {
    text-align: center; }
  .gift #contents #gift {
    padding: 104px 0 0; }
    .gift #contents #gift h2 {
      margin-bottom: 37px; }
    .gift #contents #gift p {
      text-align: center; }
    .gift #contents #gift #flow {
      margin-top: 55px; }
  .gift #contents #showcase {
    padding: 90px 0 115px;
    margin-bottom: 55px; }
    .gift #contents #showcase h2 {
      margin-bottom: 50px; }
  .gift #contents #line {
    padding: 55px 0 101px; }
    .gift #contents #line h2 {
      margin-bottom: 50px; }
    .gift #contents #line #flow .flow .text p img {
      margin-right: 4rem; }
  .gift #contents #delivery {
    padding: 70px 0 145px;
    margin-bottom: 55px; }
    .gift #contents #delivery img {
      margin-bottom: 50px;
      margin-left: 50px; }
    .gift #contents #delivery .fee {
      border-left: 1px solid #61392f;
      padding-left: 20px; }
      .gift #contents #delivery .fee.free {
        width: 25%; }
      .gift #contents #delivery .fee.paid {
        width: 30%; }
      .gift #contents #delivery .fee.other {
        width: 45%; }
      .gift #contents #delivery .fee p {
        margin: 28px 0 0; } }

@media screen and (max-width: 48em) {
  .gift #contents p {
    font-size: 14px !important; }
  .gift #contents #lead p {
    margin-bottom: 2rem; }
  .gift #contents #gift {
    padding: 34px 0 0; }
    .gift #contents #gift h2 {
      margin-bottom: 27px; }
    .gift #contents #gift p.gift-illust img {
      padding: 0 10px 0 60px; }
    .gift #contents #gift #flow {
      margin-top: 35px; }
  .gift #contents #showcase {
    padding: 30px 0 45px;
    margin-bottom: 5px; }
    .gift #contents #showcase h2 {
      margin-bottom: 30px; } }
  @media screen and (max-width: 48em) and (max-width: 36em) {
    .gift #contents #showcase .slider .slick-prev {
      left: -5px; }
    .gift #contents #showcase .slider .slick-next {
      right: -5px; } }

@media screen and (max-width: 48em) {
  .gift #contents #line {
    padding: 35px 0 50px; }
    .gift #contents #line h2 {
      margin-bottom: 15px;
      letter-spacing: 0; }
    .gift #contents #line p.line-mark {
      margin-bottom: 10px; }
      .gift #contents #line p.line-mark img {
        width: 80px; }
    .gift #contents #line #flow .flow {
      padding: 30px 30px 28px;
      flex-direction: column;
      justify-content: center; }
      .gift #contents #line #flow .flow .num, .gift #contents #line #flow .flow .text {
        width: 100%; }
      .gift #contents #line #flow .flow .num {
        text-align: center;
        margin-bottom: 15px; }
  .gift #contents #delivery {
    padding: 30px 0 25px;
    margin-bottom: 45px; }
    .gift #contents #delivery h2 {
      margin-bottom: 30px; }
    .gift #contents #delivery .delivery-illust {
      max-width: 90%; }
    .gift #contents #delivery .row .row {
      flex-direction: column; }
    .gift #contents #delivery .fee {
      padding-left: 20px;
      width: 100%; }
      .gift #contents #delivery .fee p {
        margin: 10px 0 25px; } }

.gift #bottom-parallax1 {
  right: 22.3vw;
  top: 2200px; }

.gift #bottom-parallax2 {
  left: 12.85vw;
  top: 3000px; }

.gift #parallax1 {
  left: 11.45vw;
  top: 1560px; }

.gift #parallax2 {
  right: 21.6vw;
  top: 2100px; }

.gift #parallax3 {
  left: 12.5vw;
  top: 4000px; }

.gift #parallax4 {
  right: 20.83vw;
  top: 5000px; }
