@import "mixin";

@charset "UTF-8";
/* ------------------------------------------------------
デスクトップ・スマホ共通
 ------------------------------------------------------ */

 $color-base: #61392f;
 $color-green: #759620;

body {
  margin: 0;
  padding: 0;
  color: $color-base;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 300;
  background: #efe7e5;
  position: relative;
  @include mq('max', 'lg') {
    &.sp-menu-open{
      overflow: hidden;
    }
  }
}
h1,h2,h3 {
  font-weight: 300;
  letter-spacing: 0.1rem;
  font-feature-settings : "palt";
}
table{margin: 1em 0;width: 100%;font-size:110%;}
th,td{vertical-align: top;padding:0.4em;border: 1px dotted #5D3F37;}
th{text-align: left;}
td ol, td ul{margin: 0;}

dt{font-weight: 600;}
dd{margin: 0 0 1em;}

a{
  color: $color-base;
  &:hover{
    color: #444;
  }
}

span.en{
  font-family: 'Libre Baskerville', serif;
  font-weight: 700;
}
span.jp{
  font-size: 50%;
  display: block;
}
/* ---------------------------
header
*/
header{
  .title{
    margin-bottom: 15px;
    a{
      color: #444;
      &:hover{
        text-decoration: none;
      }
    }
  }
  .sns{
    li{
      font-size: 24px;
      &#nav-sns-youtube a{
        color: #ff0500;
      }
      &#nav-sns-line{
        height: 29px;
        width: 29px;
        border-radius: 50%;
        overflow: hidden;
        justify-content: center;
        display: flex;
        align-items: center;
        border:2px solid #25C131;
        margin-right: .2rem;
        background: #fff;
        a{
          color: #25C131;
          font-size: 32px;
        }
      }
    }
  }
  @include mq('min', 'lg') {
    .container{
      position: relative;
      max-width: 1024px;
      @include mq('max', 'lg'){
        max-width: 900px;
      }
    }
    .title{
      padding:58px 0 20px;
      text-align: center;
      a{
        justify-content: center;
        font-size: 1.6rem;
        font-weight: 400;
      }
      img{
        width:180px;
      }
    }
    nav{
      display: flex;
      justify-content:center;
      margin-bottom: 18px;
    }
    ul{
      display: flex;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      &.gnav{
        li{
          padding:0 .4rem;
          font-feature-settings : "palt";
          text-align: center;
          a{
            padding: .2rem .9rem;
            text-decoration: none;
            display: block;
            font-size: 19px;
            font-weight: 800;
            span.jp{
              font-size: 11px;
              font-weight: 400;
              display: block;
            }
            &:hover{
              text-decoration: none;
              color: $color-green;
              // border-bottom-color: #eee;
            }
          }
        }
      }
      &.sns{
        align-items: center;
        li{
          a{
            padding: .2rem .4rem ;
          }
        }
      }
    }
    .overlay, .menu-trigger{
      display: none;
    }
    .sns{
      position: absolute;
      top:15px;
      right:20px;
    }
  }
  @include mq('max', 'lg') {
    // background: rgba(255,255,255,0.9);
    position: relative;
    .container{
      max-width: none;
    }
    .title{
      padding: 3px 0 14px;
      z-index: -1;
      margin-bottom: 0;
      .logo{
        font-size: 2rem;
        img{
          width: 160px;
        }
      }
      div{
        font-size: .7rem;
        strong{
          font-size: 1rem;
        }
      }
    }
		.menu-trigger {
			display: inline-block;
			width: 28px;
			height: 34px;
			vertical-align: middle;
			cursor: pointer;
			position: fixed;
			top: 33px;
			right: 18px;
			z-index: 2002;
			transform: translateX(0);
			span {
				display: inline-block;
				box-sizing: border-box;
				position: absolute;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: $color-base;
				transition: all .5s;
				&:nth-of-type(1) {
					top: 3px;
				}
				&:nth-of-type(2) {
					top: 9px;
				}
				&:nth-of-type(3) {
					top: 15px;
				}
			}
      div{
        font-size: 10px;
        text-align: center;
        bottom: 0;
				position: absolute;
        letter-spacing: -.04rem;
      }
			&.active {
				transform: translateX(0);
				z-index: 10000;
				span {
					background-color: $color-base !important;
					&:nth-of-type(1) {
						transform: translateY(6px) rotate(-45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						transform: translateY(-6px) rotate(45deg);
					}
				}
			}
		}
    nav{
      display: none;
	  	width: 100vw;
	    height: 100%;
      position: fixed;
	    left: 0;
	    top: 0;
			z-index: 1600;
	    transform: translate(-100vw);
	    transition: all .5s;
	    overflow-y:scroll !important;
	    overflow-x:hidden !important;
      padding:100px 0 0;
      ul {
  	    margin: 0 0 20px;
        padding:0 35px;
  			li {
  				padding: .8rem 0;
          border-bottom: 1px solid transparent;
          &:hover{
            border-bottom: 1px solid $color-base;
          }
  	      a {
  	        color: $color-base;
  	        display: block;
  	        text-decoration: none;
            border-bottom: 1px solid transparent;
            &:hover{
              text-decoration: none;
              // border-bottom-color: #eee;
            }
            span{
              vertical-align: middle;
            }
            span.jp{
              display: inline-block;
              padding-left: .5rem;
              font-size: .8rem;
              font-feature-settings : "palt";
              letter-spacing: .08rem;
            }
  	      }
  	    }
        &.sns{
          display: flex;
          align-items: center;
          li{
            display: flex;
            align-items: center;
            padding: 2px 0;
            margin:0 .4rem;
            justify-content: center;
            border-bottom: 0;
              height: 29px;
              width: 29px;
          }
          li#nav-sns-line{
            padding-top: 0;
            a{
              padding-top: 4px;
            }
          }
          li#nav-sns-instagram{
            margin-left: .1rem;
          }
        }
      }
	    &.open {
        display: block;
        transform: translateX(0);
        overflow:hidden;
        z-index: 10000;
        ul{
  	      box-sizing:border-box;
  		    li {
            list-style: none;
  				}
  	    }
  		}
    }
    .overlay {
	    content: "";
	    display: block;
	    width: 0;
	    height: 0;
	    background: rgba(255,255,255,.9);
	    position: fixed;
	    top: 0;
	    left: 0;
	    opacity: 0;
	    transition: opacity .5s;
	    top: 0;
      z-index: 0;
	    &.open {
	      width: 100%;
	      height: 100%;
	      opacity: 1;
  	    z-index: 1001;
	    }
	  }
    @include mq('max', 'md') {
      .title{
        padding-bottom: 12px;
        .logo{
          img{
            width: 110px;
            margin-top: 5px;
          }
        }
      }
      .menu-trigger {
        top:25px;
      }
      nav{
        &.open{
          padding:90px 0 0;
        }
      }
    }
  }
}

.parallax{
  position: fixed;
  z-index: 1000;
  img{
    height: auto;
    &.f1{
      width: 10.55vw;
      max-width: 153px;
    }
    &.f2{
      width: 8.88vw;
      max-width: 128px;
    }
    &.f3{
      width: 13.06vw;
      max-width: 188px;
    }
    &.f4{
      width: 11.25vw;
      max-width: 162px;
    }
    &.f5{
      width: 6.6vw;
      max-width: 95px;
    }
    &.f6{
      width: 10.14vw;
      max-width: 146px;
    }
    &.f7{
      width: 9.86vw;
      max-width: 142px;
    }
    &.f8{
      width: 9.44vw;
      max-width: 136px;
    }
  }
  &#top-parallax1{
    left:28.33vw;
    top:-2.43vw;
    display: block;
    @include mq('max', 'lg') {
      position: absolute;
      left: 40vw
    }
  }
  &#top-parallax2{
    left:12.85vw;
    top:52.08vw;
  }
  &#top-parallax3{
    right:10.76vw;
    top:60.07vw;
  }
  &#bottom-parallax3{
    right:5.07vw;
    bottom:45px;
    position: absolute;
    display: block;
    @include mq('max', 'sm') {
      right: 2vw;
      img{
        width: 15vw;
      }
    }
  }
  @include mq('max', 'lg') {
    display: none;
    &#bottom-parallax1{
      position: absolute;
      display: block;
      top:auto !important;
      right: 10vw !important;
      bottom:360px;
      z-index: 4000;
      img{
        width:15.5vw !important;
      }
    }
    &#bottom-parallax2{
      position: absolute;
      display: block;
      top:auto !important;
      left:4vw !important;
      bottom:245px;
      img{
        width: 12vw !important;
      }
    }
  }
}


/* ---------------------------
footer
*/
footer {
  text-align: center;
  h2{
    span.jp{
      padding-top: .3rem;
      font-weight: 600;
    }
  }
  .links{
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      height: 120px;
      width: 120px;
      margin:0 23px;
    }
  }
  .copyright {
    background: #fff;
    font-size: 18px;
    font-family: 'Libre Baskerville', serif;
    font-weight: 700;
    padding: 17px;
  }

  @include mq('min', 'md') {
    padding:100px 0 0;
    h2{
      margin-bottom: 32px;
      font-size: 32px;
    }
    .links{
      margin-top: 55px;
    }
    .copyright {
      margin-top: 238px;
    }
    @include mq('max', 'lg') {
        padding-top: 0;
        .copyright {
          margin-top: 80px;
        }
    }
  }

  @include mq('max', 'md') {
    padding-top:15px;
    h2{
      margin-bottom: 28px;
      font-size: 22px;
    }
    p{
      font-size: 14px;
    }
    .links{
      margin-top: 15px;
      a{
        height: 80px;
        width: 80px;
        margin:0 8px;
        display: block;
        img{
          width: 100%;
        }
      }
    }
    .copyright {
      margin-top: 80px;
      font-size: .8rem;
    }
  }
}
#page-top {
  position: fixed;
  display: none !important;
  @include mq('min', 'md'){
    bottom: 20px;
    z-index: 3000;
  }
  @include mq('max', 'md'){
    bottom: 10px;
  }
  right: 10px;
  a {
    display: block;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    color: $color-base;
    text-decoration: none;
    font-size: 10px;
    border-radius:50%;
    height: 50px;
    width: 50px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    span{
      display: block;
    }
  }
}


/* ---------------------------
contents
*/
#contents{
  p{
    font-size: 1rem;
    line-height: 1.8;
    a{
      text-decoration: underline;
    }
  }
  h2 {
    font-size: 1.875rem;
    margin-top: 0.4rem;
    font-feature-settings : "palt";
    span{
      font-size: 1rem;
    }
    &.heading{
      span.en{
        letter-spacing: -.01rem;
      }
      span.jp{
        font-weight: 600;
      }
      @include mq('min', 'md'){
        span.en{
          font-size: 32px;
        }
        span.jp{
          font-size: 14px;
          margin-top: .2rem;
        }
      }
      @include mq('max', 'md'){
        span.en{
          font-size: 22px;
        }
        span.jp{
          font-size: 12px;
          margin-top: .3rem;
        }
      }
    }
  }
  h3 {
    font-size: 1.3rem;
    margin-top: 0.4rem;
  }
  li{
    a{
      text-decoration: underline;
    }
  }
  @include mq('min', 'lg') {
    padding: 45px 0 30px;
  }
  @include mq('max', 'md'){
    h2{
      font-size: 1.4rem;
    }
    h3 {
      font-size: 1.1rem;
    }
    #lead{
      font-feature-settings : "palt";
    }
  }
  .content-nav{
    a{
      background: #f9f9f9;
      padding: .5rem;
      border-radius: 3px;
      display: block;
      margin-bottom: 1rem;
      &:hover{
        text-decoration: none;
        background: #f5f5f5;
      }
    }
  }
  .viewmore{
    text-align: center;
  }
  a.btn{
    display: inline-block;
    text-align: center;
    background: #603930;
    color: #fff;
    border: 2px solid #603930;
    border-radius: 10px;
    padding: .9rem 3rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    &:hover{
      background: #fff;
      color: #603930;
    }
    @include mq('max', 'xs'){
      padding: .6rem 1rem;
      width: 100%;
    }
  }
  .btn-primary{
    font-weight: 300;
  }
}
#headline{
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq('min', 'lg') {
    margin-top: 30px;
    height: 25.34vw;
  }
  @include mq('max', 'lg') {
    margin-bottom: 2rem;
    height: 32vw;
  }
  h1{
    text-align: center;
    font-weight: 300;
    margin-bottom: 0;
    @include mq('min', 'lg') {
      font-size: 1.875rem;
      img{
        margin-top: 3.4vw;
      }
      span{
        padding: 10px 0 1.8rem;
        color: #fff;
        font-size: 12px;
      }
    }
    @include mq('max', 'lg') {
      font-size: 1.3rem;
      letter-spacing: .1rem;
      img{
        max-width:60%;
      }
      span{
        padding: 0 1rem .5rem;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

/* ---------------------------
ホーム
*/
.home{
  .movie {
    position: relative;
    width: 100%;
    #top-movie {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .feature-title{
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2000;
      position: absolute;
      top:0;
      width: 100%;
      height: 100%;
      img{
        width: 80%;
        max-width: 563px;
      }
    }
  }
  #contents{
    z-index: 1000 !important;
    position: relative;
    @include mq('min', 'lg') {
      padding-top: 0;
    }
    h2{
      margin-bottom: 1.7rem;
      a{
        text-decoration: none;
      }
    }
    #instagram{
      min-height: 440px;
      h2{
        font-size: 2.7rem;
        margin-top: 0;
      }
      @include mq('min', 'lg') {
        h2{
          margin-bottom: 1.3rem;
        }
      }
      @include mq('max', 'lg') {
        padding: 10px 0 0;
        h2{
          margin-bottom: .9rem;
        }
      }
    }
  }
  .insta-photos{
    a:before, div:before{
      content: "";
      display: block;
      padding-top: 100%;
    }
    a img, a video, div img, div video{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: bottom;';
    }
  }
  @include mq('min', 'md') {
    #feature{
      max-height: 728px;
      overflow: hidden;
    }
    .movie {
      padding-top: 56.25%;
    }
    .feature-title{
      max-height: 728px;
    }
    #shopinfo {
      h2{
        font-size: 17px;
        font-weight: 700;
        line-height: 1.9;
        margin-bottom: 2rem;
        text-align: center;
      }
      img{
        margin-bottom: 2rem;
        max-width: 405px;
      }
    }
  }
  @include mq('max', 'md') {
    .movie {
      padding-top: 75%;
      overflow: hidden;
      #top-movie {
        transform: translate(-12.5%,0%);
        height: 75vw;
        width:auto;
      }
    }
    #shopinfo {
      h2{
        font-size: 14px;
        font-weight: 700;
        line-height: 1.9;
        margin-bottom: 2rem;
        text-align: left;
      }
    }
  }
  #bottom-parallax1{
    right:20.3vw;
    top:2550px;
    @include mq('max', 'xl') {
      right:14vw;
    }
  }
  #bottom-parallax2{
    left:12.85vw;
    top:2800px;
  }
  #parallax1{
    right:12.57vw;
    top:365px;
  }
  #parallax2{
    left:9.51vw;
    top:230px;
    @media (max-width: 1340px){
      left:1vw;
    }
  }
  #parallax3{
    left:9.51vw;
    top:1520px;
  }
  #parallax4{
    right:23vw;
    top:935px;
  }
  #parallax5{
    right:15.28vw;
    top:1760px;
  }
  #parallax6{
    left:27.78vw;
    top:2230px;
    @include mq('max', 'xl') {
      left:22vw;
    }
  }
}

/* ---------------------------
お店の紹介
*/
.about {
  #headline{
    background: url('/_uploads/about-headline-bg.jpg') no-repeat / cover ;
  }
  #contents{
    h2 {
      font-family: 'Libre Baskerville', serif;
      font-weight: 700;
      .small {
        font-size: 1rem;
        display: inline-block;
        margin-left: 0.5rem;
      }
    }
    #lead{
      p{
        line-height: 2;
      }
    }
    #store {
      background-color:#f6f2f1;
      background-image: url('/_assets/img/bg1-t.png'), url('/_assets/img/bg1-b.png');
      background-position: top center , bottom center;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      width: 100vw;
      img.store-illust{
        margin-bottom: 30px;
      }
      h3{
        font-weight: 600;
        margin:0 0 1.2rem;
      }
      p{
        line-height: 2.2;
        color: $color-base;
        font-size: 13px;
        font-weight: 600;
      }

      img.store-pay{
        margin-top: 10px;
        @include mq('min', 'md'){
          max-width: 380px
        }
      }
    }
    #staff{
      padding-bottom: 30px;
      h2{
        margin-bottom: 1.8rem;
      }
      p{
        text-align: center;
        margin-top: .8rem;
      }
      .about-staff{
        mask-image: url('/_uploads/about-staff-mask.png');
        mask-repeat:no-repeat;
        mask-size:100%;
      }
    }
    #map{
      background-color:#f6f2f1;
      background-image: url('/_assets/img/bg2-t.png'), url('/_assets/img/bg2-b.png');
      background-position: top center , bottom center;
      background-repeat: no-repeat;
      background-size: contain;
      .gmap{
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @include mq('min', 'md') {
    #contents{
      #lead{
        padding-bottom: 70px;
        p{
          text-align: center;
          img{
            margin-top: 15px;
            max-width: 468px;
          }
        }
      }
      #store{
        padding:55px 0 90px;
        h2{
          margin-bottom: 60px;
        }
      }
      #map{
        padding:75px 0 1px;
        margin-bottom: 55px;
        p{
          font-size: 16px;
          font-weight:700;
          margin-bottom: 2.5rem;
          &.viewmore{
            margin-top: 40px;
          }
        }
      }
    }
  }
  @include mq('max', 'md') {
    #contents{
      padding-bottom: 40px;
      #lead{
        padding-bottom: 30px;
        p{
          font-size: 14px;
        }
      }
      #store{
        padding:35px 0 40px;
        h2{
          margin-bottom:20px;
        }
        p{
          font-feature-settings : "palt";
          img.store-pay{
            margin:15px 0 20px;
            display: block;
          }
        }
      }
      #staff{
        padding-top: 10px;
      }
      #map{
        padding:30px 0 40px;
        h2{
          margin-bottom: 20px;
        }
        p{
          font-size: 14px;
          font-weight: normal;
          margin-bottom: 20px;
        }
      }
    }
  }
  #bottom-parallax1{
    right:22.3vw;
    top:2200px;
  }
  #bottom-parallax2{
    left:12.85vw;
    top:3000px;
  }
  #parallax1{
    left:32.08vw;
    top:1560px;
    @media (min-width: 1500px){
      left:5vw;
    }
  }
  #parallax2{
    right:21.6vw;
    top:2100px;
  }
  #parallax3{
    left:14.375vw;
    top:2600px;
  }
  #parallax4{
    left:29.86vw;
    top:110.05vw;
  }
  #parallax5{
    right:19.8vw;
    top:130.21vw;
  }
  #parallax6{
    left:19.8vw;
    top:135.21vw;
  }
}


/* ---------------------------
体験・教室
*/
.lesson {
  #headline{
    background: url('/_uploads/lesson-headline-bg.jpg') no-repeat / cover ;
    margin-bottom: 0;
    h1{
      img{
        padding-left:2vw;
        padding-right:5vw;
      }
    }
  }
  #contents{
    padding-top: 0;
  }
  #lead{
    background:#f6f2f1 url('/_assets/img/bg3-b.png') bottom center no-repeat /contain;
    p{
      font-size: .95rem;
      line-height: 2.1;
    }
  }
  #workshop, #lesson{
    .row{
      .post{
        margin: 0 auto;
        iframe{
          width:100%;
          min-width: 100% !important;
        }
      }
    }
    .viewmore{
      margin: 35px 0 38px;
      .btn{
        padding:1.15rem 1.4rem;
        font-size: 1rem;
      }
      @include mq('max', 'md') {
        margin-bottom: 50px;
      }
    }
  }
  #workshop{
    p.ws-image img{
      mask-image: url('/_uploads/lesson-ws-mask.png');
      mask-repeat:no-repeat;
      mask-size:100%;
      object-fit: contain;
    }
  }
  #lesson{
    background-color:#f6f2f1;
    background-image: url('/_assets/img/bg4-t.png'), url('/_assets/img/bg4-b.png');
    background-position: top center , bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    .lesson-image img{
      mask-image: url('/_uploads/lesson-lesson-mask.png');
      mask-repeat:no-repeat;
      mask-size:100%;
      object-fit: contain;
    }
    p{
      font-size: 14px;
      line-height: 2;
    }
  }
  @include mq('min', 'md') {
    #lead{
      padding:40px 0 48px;
      margin-bottom: 62px;
      p{
        text-align: center;
      }
    }
    #workshop{
      h2{
        margin-bottom: 50px;
      }
      p.ws-image{
        margin-bottom: 35px;
        &+p{
          text-align: center;
        }
      }
    }
    #lesson{
      padding:80px 0;
      margin-bottom: 78px;
      h2{
        margin-bottom: 60px;
      }
      p{
        text-align: center;
      }
      .lesson-image{
        margin-bottom: 65px;
      }
    }
  }
  @include mq('max', 'md') {
    #contents{
      p{
        font-size: 14px;
      }
    }
    #headline{
      margin-bottom: 0;
    }
    #lead{
      padding:40px 0 38px;
      margin-bottom: 32px;
    }
    #workshop{
      h2{
        margin-bottom: 30px;
      }
      p.ws-image{
        margin-bottom: 25px;
      }
    }
    #lesson{
      padding:44px 0 30px;
      @include mq('min', 'sm') {
        padding-bottom: 80px;
      }
      margin-bottom: 35px;
      h2{
        margin-bottom: 30px;
      }
      .lesson-image{
        margin-bottom: 30px;
      }
    }
  }
  #bottom-parallax1{
    right:22.3vw;
    top:2200px;

    @media (max-width: 1440px){
      right:14vw;
    }
  }
  #bottom-parallax2{
    left:12.85vw;
    top:3000px;
  }
  #parallax1{
    left:20.13vw;
    top:1500px;
  }
  #parallax2{
    right:21.6vw;
    top:2100px;
  }
  #parallax3{
    left:12.5vw;
    top:4000px;
  }
  #parallax4{
    right:20.83vw;
    top:5000px;
  }
}

/* ---------------------------
ギフト
*/
.gift {
  #headline{
    background: url('/_uploads/gift-headline-bg.jpg') no-repeat / cover ;
  }
  #contents{
    h2{
      letter-spacing: .2rem;
    }
    #lead{
      p{
        font-size: .95rem;
        line-height: 2;
      }
    }
    #gift{
      background:#f6f2f1 url('/_assets/img/bg5-t.png') top center no-repeat /contain;
      padding:104px 0 0;
      p.gift-illust{
        margin-bottom: 35px;
      }
    }
    #showcase{
      background:#f6f2f1 url('/_assets/img/bg5-b.png') bottom center no-repeat /contain;
      .slider{
        .slick-arrow{
          z-index: 10000;
          height: 64px;
          width:18px;
          top:30%;
        }
        .slick-prev{
          left: -20px;
        }
        .slick-next{
          right: -20px;
        }
        .slick-prev:before{
          content: url('/_assets/img/slick-arrow-left.png');
        }
        .slick-next:before{
          content: url('/_assets/img/slick-arrow-right.png');
        }
        .slick-prev:before, .slick-next:before{
          height: 64px;
          width:18px;
        }
        .slide{
          padding:0 8px;
          p{
            padding:15px 0;
            font-size: 14px;
            line-height: 1.75;
            span{
              font-size: 80%;
              padding:0 .1rem;
            }
          }
        }
      }
    }
    #line{
      p.line-mark{
        margin-bottom: 42px;
      }
      #flow{
        .flow{
          border:1px solid $color-base;
          display: flex;
          padding:40px 50px 28px;
          .num{
            font-family: 'Libre Baskerville', serif;
            font-weight: 700;
            font-size: 24px;
            padding-right: 20px;
            width: 4rem;
          }
          .text{
            width: calc(100% - 4rem);
            padding-top: 5px;
            p{
              font-size: 14px;
            }
          }
        }
      }
    }
    #delivery{
      background-color:#f6f2f1;
      background-image: url('/_assets/img/bg6-t.png'), url('/_assets/img/bg6-b.png');
      background-position: top center , bottom center;
      background-repeat: no-repeat;
      background-size: contain;
      padding:70px 0 145px;
      margin-bottom: 55px;
      h2{
        margin-bottom: 3.6rem;
      }
      .fee{
        p{
          font-size: 13px;
          line-height: 2.3;
        }
      }

      p{
        line-height: 1.5;
      }
    }
  }
  @include mq('min', 'md') {
    #contents{
      #lead{
        p{
          text-align: center;
        }
      }
      #gift{
        padding:104px 0 0;
        h2{
          margin-bottom: 37px;
        }
        p {
          text-align: center;
        }
        #flow{
          margin-top: 55px;
        }
      }
      #showcase{
        padding:90px 0 115px;
        margin-bottom: 55px;
        h2{
          margin-bottom: 50px;
        }
      }
      #line{
        padding:55px 0 101px;
        h2{
          margin-bottom: 50px;
        }
        #flow .flow{
          .text{
            p{
              img{
                margin-right:4rem;
              }
            }
          }
        }
      }
      #delivery{
        padding:70px 0 145px;
        margin-bottom: 55px;
        img{
          margin-bottom: 50px;
          margin-left: 50px;
        }
        .fee{
          border-left: 1px solid $color-base;
          padding-left: 20px;
          &.free{
            width: 25%;
          }
          &.paid{
            width: 30%;
          }
          &.other{
            width: 45%
          }
          p{
            margin: 28px 0 0;
          }
        }
      }
    }
  }
  @include mq('max', 'md') {
    #contents{
      p{
        font-size: 14px !important;
      }
      #lead{
        p{
          margin-bottom: 2rem;
        }
      }
      #gift{
        padding:34px 0 0;
        h2{
          margin-bottom: 27px;
        }
        p.gift-illust{
          img{
            padding:0 10px 0 60px;
          }
        }
        #flow{
          margin-top: 35px;
        }
      }
      #showcase{
        padding:30px 0 45px;
        margin-bottom: 5px;
        h2{
          margin-bottom: 30px;
        }
        .slider{
          @include mq('max', 'sm') {
            .slick-prev{
              left: -5px;
            }
            .slick-next{
              right: -5px;
            }
          }
        }
      }
      #line{
        padding:35px 0 50px;
        h2{
          margin-bottom: 15px;
          letter-spacing: 0;
        }
        p.line-mark{
          margin-bottom: 10px;
          img{
            width: 80px;
          }
        }
        #flow{
          .flow{
            padding:30px 30px 28px;
            flex-direction: column;
            justify-content: center;
            .num, .text{
              width: 100%;
            }
            .num{
              text-align: center;
              margin-bottom: 15px;
            }
          }
        }
      }
      #delivery{
        padding:30px 0 25px;
        margin-bottom: 45px;
        h2{
          margin-bottom: 30px;
        }
        .delivery-illust{
          max-width: 90%;
        }
        .row .row{
          flex-direction: column;
        }
        .fee{
          padding-left: 20px;
          width: 100%;
          p{
            margin: 10px 0 25px;
          }
        }
      }
    }
  }

  #bottom-parallax1{
    right:22.3vw;
    top:2200px;
  }
  #bottom-parallax2{
    left:12.85vw;
    top:3000px;
  }
  #parallax1{
    left:11.45vw;
    top:1560px;
  }
  #parallax2{
    right:21.6vw;
    top:2100px;
  }
  #parallax3{
    left:12.5vw;
    top:4000px;
  }
  #parallax4{
    right:20.83vw;
    top:5000px;
  }
}
